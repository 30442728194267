<template>
  <div>
    <ayl-berad-nav :nav="nav"/>
    <div class="content-box">
      <div class="content-main">
    <ayl-table :table = "table">
      <div slot="ctrl-button">
        <div class="export-btn" @click="dialogExport=true">导出</div>
      </div>
    </ayl-table>
    </div>
    </div>
    <!--导出-->
    <el-dialog title="选择导出的数据" :visible.sync="dialogExport" width="540px" center>
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      <el-checkbox-group v-model="checkIndex" @change="handleCheckedCitiesChange">
        <el-checkbox :label="item.index" v-for="(item, idx) in checkList" :key="idx">{{item.value}}</el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="exportExcel" :loading="btnLoading">确 定</el-button>
        <el-button @click="dialogExport=false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<!-- 加油情况 -->
<script>
import mixins from '../../../utils/mixins'
  export default {
    mixins: [mixins.$exportCheckAll],
    data () {
      const vm = this
      return {
        nav: [{name: "油耗管理", path: ""}, {name: "加油情况", path: ""}],
        list: [],
        dialogExport: false,
        btnLoading: false,
        tableLoading: false,
        checkList: [],
        checkIndex: [],
        carBrandList: [],// 品牌型号
        table: {
          api: vm.$api.listOilEventInfo,
          query: {
            startTime: null,
            endTime: null,
            status: null,
            queryContent: null,
            eventType: '加油',
            carBrand: null,
          },
          searchData: [{
            type: 'select',
            title: '品牌型号',
            model: 'carBrand',
            placeholder: '请选择状态',
            option: vm.carBrandList,
          },
          {
            type: 'dateSection',
            title: '时间设置',
            startDate: ' ',
            endDate: ' ',
            modelName: ['startTime', 'endTime']
          },
          {
            type: 'input',
            model: 'queryContent',
            placeholder: '车牌号/设备号',
            maxlength: '12'
          } ],
          columns: [{
            title: '序号',
            width: '50px',
            $index: 'index',
          },{
            title: '车牌号',
            key: 'plateNumber',
            width: "120px"
          }, {
            title: '品牌型号',
            key: 'carBrand',
            width: "120px"
          }, {
            title: '设备号',
            key: 'deviceName',
            width: '120px'
          }, {
            title: '开始加油时间',
            key: 'startTime',
            filter: 'str2ymdhm',
            width: '150px'
          },{
            title: '加油结束时间',
            key: 'endTime',
            filter: 'str2ymdhm',
            width: '150px'
          },{
            title: '加油开始时油量(L)',
            key: 'startOilMass',
            width: '150px'
          },{
            title: '加油结束时油量(L)',
            key: 'endOilMass',
            width: '150px'
          },{
            title: '加油量(L)',
            key: 'differenceOilMass',
            width: '120px',
            sortable: 'custom',
          },
          {
            title: '车辆位置',
            key: 'location',
          },
          {
            title: '操作',
            width: '100px',
            render (h, ctx) {
              return h('span', [
                h('span', {
                  class:{
                    'table-view' : true,
                  },
                  on: {
                    click: vm.details.bind(this, ctx.row)
                  }
                }, '查看详情')
              ])
            }
          }]
        }
      }
    },
    methods:{
      details(val){
        this.$router.push({
          path:'/oil-add/oil-add-details',
          query: {
            plateNumber: val.plateNumber,
            startTime: val.startTime
          }
        });
      },
      //导出
      async exportExcel(){
        this.dialogExport = true
        try {
          const url = await this.$api.reportExcel({
            params: {
              eventType: '加油',
              carBrand: this.table.query.carBrand,
              startTime: this.table.query.startTime,
              endTime: this.table.query.endTime,
              queryContent: this.table.query.queryContent,
              orderMode: this.table.query.orderMode,
              orderRule: this.table.query.orderRule,
            },
            excelKey: 'listOilEventInfoOfAdd',
            indexList: this.checkIndex,
          })
          window.open(url)
          this.dialogExport = false
        }catch (e) {}
        this.btnLoading = false
      },
    },
    async mounted () {
      /**
       * 获取品牌型号
       */
      const carBrandList = await this.$api.getListCarBrand({})
      this.table.searchData[0].option = carBrandList.map(e => {
        return {
          value: e,
          label: e
        }
      })
      await this.$search(this.table)
      // 导出
      this.$api.GetExcelExportKeyAndColumn({
        excelKey: "listOilEventInfoOfAdd"
      }).then((res)=>{
        this.checkList = res.chsList
        this.checkIndex = this.checkList.map(e=>{
          return e.index
        })
      })
    }
  }
</script>
<style lang='sass' scoped>
/deep/ .table-view-disable
  padding: 0px 9px
  border-left: 1px solid $grayShallow3
  color: #ff999c
/deep/ .table-view-edit
  padding-right: 9px
  color: #99c0ff
</style>
